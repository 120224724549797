import UILogo from './UILogo'
import logoImg from '../assets/images/n3-logo.png'
import { TypeH4 } from './UITypography'
import UIHeading from './UIHeading'
import React from 'react'

export const UITopBar = () => {

  return (
    <UIHeading>
      <UILogo src={logoImg} loggedin />
      <TypeH4>Welcome to N3 Networking</TypeH4>
    </UIHeading>
  )
}

