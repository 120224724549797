import styled from 'styled-components'
import { colours } from './variables'

const UIProfileExperience = styled.div`
  width: auto;
  text-align: center;
  padding: 0.5rem;
  color: ${colours.grey};
  clear: both;
  display: block;
  top: -3rem;
  position: relative;
  font-weight: normal;
  font-size: 0.875rem;
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin: 0 auto -1rem;
`

export default UIProfileExperience
