import React from 'react'
import { decorate, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { Close } from '@material-ui/icons'
import { updateUser } from '../Firestore/Firestore'
import INDUSTRIES from '../data/industries'
import InputLabel from '@material-ui/core/InputLabel'
import 'firebase/firestore'
import UIButton from '../UIComponents/UIButton'
import UISpacer from '../UIComponents/UISpacer'

class IndustriesDropdowns extends React.Component<{ user: any, history: any }> {
  fieldsToUpdate: any

  componentWillMount (): void {
    const industries = this.props.user.industries
    if (industries) {
      industries.forEach(industry => {
        this.dropdowns.push(industry)
      })
    }
    console.log(this.props.user)
  }

  updateIndustries (data) {
    this.fieldsToUpdate = {
      industries: data
    }
    void updateUser(this.props.user, this.fieldsToUpdate)
  }

  dropdowns: string[] = []

  industriesList = INDUSTRIES

  onAdd () {
    this.dropdowns.push(this.industriesList[0].name)
    this.updateIndustries(this.dropdowns)
  }

  onDelete (index: number) {
    this.dropdowns.splice(index, 1)
    this.updateIndustries(this.dropdowns)
  }

  onChange (index: number, newValue: any) {
    this.dropdowns[index] = newValue
    this.updateIndustries(this.dropdowns)
  }

  saveAndGoToNextPage = () => {
    const fields = {
      chatFinished: false,
      chatStarted: false,
      readyToChat: false
    }
    updateUser(this.props.user, fields).then(() => {
      this.props.history.push('/search')
    })
  }

  render () {
    return (
      <div className='dropdowns'>
        {this.dropdowns.map((value, ix) => (
          <div key={`delete${ix}`} className='dropdown-wrap'>
            <div className={ix > 0 ? 'extra-choices' : 'first-choice'}>
              <Dropdown
                value={value}
                id={`dropdown-key${ix}`}
                industries={this.industriesList}
                onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                  this.onChange(ix, e.currentTarget.value)
                }
              />
            </div>
            <UIButton
              delete
              style={{ display: ix > 0 ? 'flex' : 'none' }}
              onClick={() => this.onDelete(ix)}
            >
              <Close />
            </UIButton>
          </div>
        ))}
        <UISpacer>
          {this.dropdowns.length === 0 ? (
            <UIButton onClick={() => this.onAdd()}>Add Industry</UIButton>
          ) : <UIButton outline onClick={() => this.onAdd()}>Add Industry</UIButton>
          }

          {this.dropdowns.length > 0 && (
            <UIButton onClick={() => this.saveAndGoToNextPage()}>
              Continue
            </UIButton>
          )}
        </UISpacer>
      </div>
    )
  }
}

decorate(IndustriesDropdowns, {
  dropdowns: observable
})

export default observer(IndustriesDropdowns)

const Dropdown = ({ value, id, industries, onChange }) => {
  return (
    <FormControl>
      <InputLabel shrink htmlFor={id}>
        Select Industry
      </InputLabel>
      <Select
        native
        name='industry'
        id={id}
        value={value}
        className='select-box industry-select'
        onChange={e => onChange(e)}
      >
        {industries
          .map((industry, ix) => (
            <option
              value={industry.name}
              id={`option${ix}`}
              key={`option-key${ix}`}
            >
              {industry.name}
            </option>
          ))}
      </Select>
    </FormControl>
  )
}
