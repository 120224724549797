import { observable } from 'mobx'
import IUser from '../models/models'

export const UserProfileStore = observable(({
  firstName: null,
  lastName: null,
  email: null,
  profileImage: null,
  experience: { description: null, level: null },
  currentPosition: '',
  lookingFor: null,
  industries: [],
  matched: false,
  matchedPeople: [],
  id: null
} as unknown) as IUser)
