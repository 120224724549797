import styled from 'styled-components'
import { base, breakpoint } from './variables'

const UIProfileImage = styled.img<{ small?: boolean}>`
  width: 6rem;
  text-align: center;
  display: block;
  border-radius: 3rem;
  box-shadow: ${base.boxShadowSmall};
  border: 2px solid #fff;
  outline: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${base.responsiveMarginTop};
  margin-bottom: ${base.responsiveMarginBottom};
  ${breakpoint.sm} {
    width: 8rem;
    border-radius: 4rem;
  }
  ${breakpoint.md} {
    width: 10rem;
    border-radius: 5rem;
  }
  ${breakpoint.lg} {
    width: 12rem;
    border-radius: 6rem;
  }
  ${props =>
  props.small === true &&
  ` width: 100% !important;
    border-radius: 5rem !important;
    margin: 0;
    padding: 0;
    ${breakpoint.sm} {
      width: 100% !important;
      border-radius: 5rem !important;
    }
    ${breakpoint.md} {
      width: 100% !important;
      border-radius: 5rem !important;
    }
    ${breakpoint.lg} {
      width: 100% !important;
      border-radius: 5rem !important;
    }
  `}
`

export default UIProfileImage
