import React from 'react'
import UIButton from '../UIComponents/UIButton'
import { TypeH2 } from '../UIComponents/UITypography'
import UISpacer from '../UIComponents/UISpacer'
import { updateUser } from '../Firestore/Firestore'
import { useAuth0 } from '../react-auth0-wrapper'
import { UITopBar } from '../UIComponents/UITopBar'

const ChooseOutcomes = props => {

  const { user }: any = useAuth0()

  const setOutcomes = (outcome: string) => {
    updateUser(user, { lookingFor: outcome }).then(() => {
      props.history.push('/add-industries')
    })
  }

  return (
    <div className='container'>
      <UITopBar />
      <div className='profile-area'>
        <TypeH2 light>
          {user.given_name}, what business outcomes are you
          looking for?
        </TypeH2>

        <UISpacer>
          <UIButton
            active={true}
            onClick={() => {
              setOutcomes('Clients and Sales')
            }}
          >
            Clients/Sales
          </UIButton>
          <UIButton
            onClick={() => {
              setOutcomes('Suppliers')
            }}
          >
            Suppliers
          </UIButton>
          <UIButton
            onClick={() => {
              setOutcomes('Clients and Sales or Suppliers')
            }}
          >
            Anyone!
          </UIButton>
        </UISpacer>
      </div>
    </div>
  )
}

export default ChooseOutcomes
