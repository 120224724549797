// COLOURS

export const colours = {
  red: '#990000',
  brightRed: '#cb1111',
  powderBlue: '#beecff',
  darkGrey: '#1b2429',
  grey: '#666',
  blue: '#3b94cb',
  steelBlue: '#256276',
  darkSteelBlue: '#1a4959',
  silver: '#dbe9ee',
  n3Red: '#d20021',
  green: '#44d177',
  bgBlue: '#c5d1e0',
  white: '#FFF',
  black: '#111',
  lightGrey: '#EEE'
}

export const buttonStyles = {
  fontSize: '0.9375rem',
  paddingHeight: '0.75rem',
  paddingWidth: '1rem'
}

// ANIM CONFIG

export const sizes = {
  sm: 320,
  smMd: 376,
  md: 420,
  lg: 540
}

export const animation = {
  ease: 'ease-in-out',
  speed: '0.3s'
}

export const generateMQString = (
  attr: string,
  xsAmount: number | string,
  smAmount: number | string,
  mdAmount: number | string,
  lgAmount: number | string,
  unit: string = ''
) => {
  return `${xsAmount}${unit};
    @media (min-width: ${sizes.sm}px) {
        ${attr}: ${smAmount}${unit};
    }
    @media (min-width: ${sizes.md}px) {
        ${attr}: ${mdAmount}${unit};
    }
    @media (min-width: ${sizes.lg}px) {
        ${attr}: ${lgAmount}${unit};
    }`
}

// BASE STYLES

export const base = {
  background: colours.bgBlue,
  containerPadding: '2rem',
  containerDisplay: 'flex',
  containerFlexDirection: 'column',
  containerAlignConent: 'center',
  codeFontFamily: '"Courier New", monospace',
  fontFamily: '"Roboto", "Droid Sans", "Helvetica Neue", sans-serif',
  fontWeightLight: 100,
  fontWeightNormal: 400,
  fontWeightSemiBold: 500,
  fontWeightBold: 700,
  responsivePaddingTop: generateMQString(
    'padding-top',
    0.5,
    0.625,
    0.75,
    0.875,
    'rem'
  ),
  responsivePaddingBottom: generateMQString(
    'padding-bottom',
    0.5,
    0.625,
    0.75,
    0.875,
    'rem'
  ),
  responsiveMarginTop: generateMQString(
    'margin-top',
    0.5,
    0.625,
    0.75,
    0.875,
    'rem'
  ),
  responsiveMarginBottom: generateMQString(
    'margin-bottom',
    0.5,
    0.625,
    0.75,
    0.875,
    'rem'
  ),
  singleMargin: '1rem',
  singlePadding: '1rem',
  boxShadow: '1px 1px 10px 0px rgba(0,0,0,0.30)',
  boxShadowSmall: '1px 1px 8px 0px rgba(0,0,0,0.20)',
  borderRadius: '0.25rem'
}

// BREAKPOINTS

export const breakpoint = {
  sm: `@media (min-width: ${sizes.sm}px)`,
  smMd: `@media (min-width: ${sizes.smMd}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`
}
