import styled from 'styled-components'
import { base } from './variables'

const UISpacer = styled.div<{half?: boolean}>`
  width: 100%;
  text-align: center;
  display: block;
  flex-direction: ${base.containerFlexDirection};
  outline: none;
  margin: ${base.containerPadding} auto;
  ${props =>
  props.half &&
  `margin: calc(${base.containerPadding} / 2) auto;`}
`
export default UISpacer
