import React, { useCallback, useEffect, useState } from 'react'
import { TypeH2 } from '../UIComponents/UITypography'
import { useAuth0 } from '../react-auth0-wrapper'
import { UITopBar } from '../UIComponents/UITopBar'
import UIButton from '../UIComponents/UIButton'
import { inject, observer } from 'mobx-react'
import UISpacer from '../UIComponents/UISpacer'
import { Grid } from '@material-ui/core'
import UIProfileImage from '../UIComponents/UIProfileImage'
import USERS from '../data/users'
import jsonpath from 'jsonpath'
import UIProfileName from '../UIComponents/UIProfileName'
import UIProfilePosition from '../UIComponents/UIProfilePosition'
import UIProfileExperience from '../UIComponents/UIProfileExperience'
import { resetMatch } from '../stores/MatchesStore'
import { updateUser } from '../Firestore/Firestore'

const Search  = inject('UserProfileStore', 'MatchesStore')(
  observer(({ UserProfileStore, MatchesStore, history }) => {

  let previousMatches = UserProfileStore.matchedPeople

  const [matched, setMatched] = useState(MatchesStore)
  const [matchedPeople, setMatchedPeople] = useState()
  const { user, loading }: any = useAuth0()

    console.log("LOOKIN: ", user.lookingFor)

  const deleteCurrentMatch = useCallback(() => {
    void updateUser(user, {
      matched: false
    })
    Object.assign(MatchesStore, resetMatch)
  },[user, MatchesStore])

  useEffect(() => {
    deleteCurrentMatch()
    setMatchedPeople(previousMatches)
    console.log("Already matched: ", UserProfileStore.matchedPeople)
  },[UserProfileStore.matchedPeople, deleteCurrentMatch])

  const goToNextPage = () => {
    history.push('/chat')
  }

  const newMatch = () => {
    deleteCurrentMatch()
  }

  const users = USERS
  const notMatchedImage = require('../assets/images/nobody.png')

    // exclude current user and previously matched users from list of possible matches

    const excludeCurrentUser = userObj => {
      console.log(users.length)
      let selfRemoved = jsonpath.query(users, `$[?(@.email !== '${userObj.email}')]`)

      if(userObj.matchedPeople.length > 0) {
          userObj.matchedPeople.map((match) => {
            return selfRemoved.map((prop, i) => {
              if(prop.email === match) {
                return selfRemoved.splice(i, 1)
              }
              return selfRemoved
            })
          }
        )
      }
      console.log("prev matches: ", selfRemoved)
      return selfRemoved
    }

    // find outcome
    const matchOutcomes = attribute => {
      let matchOutcomesQuery = ''

      if (attribute !== 'Whoever') {
        matchOutcomesQuery = `$[?(@.lookingFor=='${attribute}')]`
        return matchOutcomesQuery
      }
      return matchOutcomesQuery
    }

    const search = userObj => {
      const matchedResult = makeMatch(userObj)
      console.log('MATCHED: ', matchedResult)
      Object.assign(MatchesStore, matchedResult[0])

      previousMatches.push(matchedResult[0].email)

      void updateUser(user, {
        matched: true,
        matchedPeople: matchedPeople
      }).then(() => {
        setMatched(MatchesStore)
      })
    }

    const makeMatch = userObj => {
      const usersFiltered = excludeCurrentUser(userObj)
      console.log('users: ', usersFiltered) // show whos left after removing doubles
      console.log('me: ', userObj)

      // get query string for matching outcomes
      const getOutcomesQuery = matchOutcomes(userObj.lookingFor)
      let matchedOutcomes

      console.log(getOutcomesQuery)
      // check for matching outcomes
      if (getOutcomesQuery !== null) {
        matchedOutcomes = jsonpath.query(usersFiltered, getOutcomesQuery)
      } else {
        matchedOutcomes = usersFiltered
      }
      console.log('matches: ', matchedOutcomes)

      let matchedIndustry, matchedCurrentPosition

      // check that there are more than 0 matches for outcome, there should be
      if (matchedOutcomes.length > 0) {
        matchedIndustry = jsonpath.query(
          matchedOutcomes,
          `$[?(@.industries=='${userObj.industries}')]`
        )
        console.log('matched industry: ', matchedIndustry)

        if (matchedIndustry.length > 1) {
          matchedCurrentPosition = jsonpath.query(
            matchedOutcomes,
            `$[?(@.currentPosition=='${userObj.currentPosition}')]`
          )

          if (matchedCurrentPosition >= 1) {
            return matchedCurrentPosition
          } else {
            return matchedIndustry
          }
        } else if (matchedIndustry.length === 1) {
          return matchedIndustry
        } else {
          return matchedOutcomes
        }
      } else {
        return 'There are no matches'
      }
    }


    if (loading || !user) {
      return <h3>"Loading..."</h3>
    }

    return (
      <div className='container'>
        <UITopBar />
        <div className='profile-area'>
          <TypeH2 light>
            Let's find your match!
          </TypeH2>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <UIProfileImage src={user.picture} alt='Profile Image' />
            <UIProfileName>You!</UIProfileName>
            <UIProfilePosition>{user.currentPosition}</UIProfilePosition>
            <UIProfileExperience>{user.experience.description}</UIProfileExperience>
          </Grid>
          <Grid item xs={6}>

            {matched.picture ?
                <UIProfileImage src={matched.picture} alt='Match Image' />
                 : <UIProfileImage src={notMatchedImage} alt='Match Image' />
            }
            <UIProfileName>{matched.given_name ?
              <>{matched.given_name}</>
              : <>???</>
            }</UIProfileName>
            <UIProfilePosition>{matched.currentPosition ?
              <>{matched.currentPosition}</>
              : <>Current Position</>
            }</UIProfilePosition>
            <UIProfileExperience>{matched.experience.description ?
              <>{matched.experience.description}</>
              : <>Experience</>
            }</UIProfileExperience>
            {/*<pre>{matched ? matched : "nothing"}</pre>*/}
          </Grid>
        </Grid>

        <UIButton
          className={
            UserProfileStore.matched ? 'hide button' : 'show button'
          }
          onClick={() => search(user)}
        >
          Let's do this
        </UIButton>

        <div className='buttons-wrap'>
          <UIButton
            outline
            className={
              UserProfileStore.matched ? 'show button' : 'hide button'
            }
            onClick={newMatch}
          >
            New Match
          </UIButton>
          <UIButton
            className={
              UserProfileStore.matched ? 'show button' : 'hide button'
            }
            onClick={goToNextPage}
          >
            Found them!
          </UIButton>
        </div>

        <UISpacer></UISpacer>

      </div>
    )
}))

export default Search
