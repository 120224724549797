import styled from 'styled-components'
import React from 'react'
import { base } from './variables'
import { TypeH5 } from './UITypography'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SvgIcon from '@material-ui/core/SvgIcon';


const UIQuestion = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 1rem;
  margin: calc(${base.singleMargin} / 2);
`


const UIQuestionHolder = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: block;
  margin-bottom: 3rem;
`

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, color: 'black', display: "block" }}
      onClick={onClick}
    ><SvgIcon style={{ width: '32px', height: '32px' }}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" fill="#333"/><path fill="none" d="M0 0h24v24H0z"/></svg></SvgIcon></div>
  );
}

const PreviousArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    ><SvgIcon style={{ width: '32px', height: '32px' }}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" fill="#333"/><path fill="none" d="M0 0h24v24H0z"/></svg></SvgIcon></div>
  );
}

export const UIIceBreakers = ({ questions }) => {

  var settings = {
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />
  }
  return (
    <UIQuestionHolder>
      <Slider {...settings}>
    {questions.map((q, i) => {
      return <UIQuestion key="q{i}"><TypeH5>{q.question}</TypeH5></UIQuestion>
      })
    }
      </Slider>
    </UIQuestionHolder>

  )
}
