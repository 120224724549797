import styled from 'styled-components'
import { base, colours, buttonStyles, animation, sizes } from './variables'

const UIButton = styled.button<{ outline?: boolean, small?: boolean, delete?: boolean, wide?: boolean, active?: boolean}>`
  background: ${colours.darkSteelBlue};
  border: 0;
  font-size: ${buttonStyles.fontSize};
  font-weight: 500;
  border-radius: 0.225rem;
  color: ${colours.white};
  padding: ${buttonStyles.paddingHeight} ${buttonStyles.paddingWidth};
  outline: none;
  margin: calc(${base.singleMargin} / 4);
  transition: all ${animation.speed} ${animation.ease};
  cursor: pointer;
  text-transform: uppercase;
  width: auto;
  min-width: 150px;
  &[disabled] {
    opacity: 0.5;
   }
  ${props =>
  props.wide &&
  `
  width: 100%;
  `};
  @media (max-width: ${sizes.smMd}px) {
      width: 100% !important;
    }
  :hover {
    background: ${colours.steelBlue};
  }
  ${props =>
  props.outline &&
  `
    background: transparent;
    box-shadow: inset 0px 0px 0px 1px ${colours.darkSteelBlue};
    box-sizing: border-box;
    color: ${colours.darkSteelBlue};
    padding: 
        calc(${buttonStyles.paddingHeight}) 
        calc(${buttonStyles.paddingWidth});
    :hover {
        background: ${colours.steelBlue};
        color: ${colours.white};
        box-shadow: inset 0px 0px 0px 1px ${colours.steelBlue};
    }
  `}
  ${props =>
  props.small &&
  `
    padding: 
        calc(${buttonStyles.paddingHeight} * .5) 
        calc(${buttonStyles.paddingWidth} * .5);
    font-size: calc(${buttonStyles.fontSize} * .75);
  `}
  ${props =>
  props.delete &&
  `
    background: ${colours.red};
    color: ${colours.white};
    margin: 0;
    padding: ${buttonStyles.paddingHeight} 0;
    text-align: center;
    width: 64px !important;
    height: 100%;
    min-width: 64px !important;
    :hover {
      background: ${colours.brightRed};
    }
    svg {
      margin: 0 auto;
    }
    @media (max-width: ${sizes.smMd}px) {
      width: 64px !important;
    }
    
  `}
  ${props =>
  props.active === true &&
  `
    background: ${colours.steelBlue};
  `}
`

export default UIButton
