import IUser from '../models/models'

const USERS: Partial<IUser>[] = [
  {
    given_name: 'Keelie',
    family_name: 'Wagner',
    email: 'lorem@sodalesnisi.org',
    name: 'Keelie Wagner',
    sub: 'linkedin|Hsd6shw7w',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F14.jpg?alt=media&token=e330fcbd-c343-471a-830a-9a7088a1199b',
    experience: { description: '10+ years', level: 5 },
    currentPosition: 'Director',
    userIndustry: 'Banking',
    lookingFor: 'Suppliers',
    industries: ['Accounting'],
    online: true
  },
  {
    given_name: 'Zenaida',
    family_name: 'Mcconnell',
    email: 'arcu.Curabitur@elementumpurus.edu',
    name: 'Some Guy',
    sub: 'linkedin|KDudDJud22',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F13.jpg?alt=media&token=12d3349e-91f3-41b7-bb3a-21f31a27b300',
    experience: { description: '5 - 10 years', level: 4 },
    currentPosition: 'Loan Officer',
    userIndustry: 'Banking',
    lookingFor: 'Suppliers',
    industries: ['Banking'],
    online: true
  },
  {
    given_name: 'India',
    family_name: 'Gonzales',
    email: 'magna.Duis.dignissim@vulputatemauris.edu',
    name: 'Some Guy',
    sub: 'linkedin|YRnkd992823',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F11.jpg?alt=media&token=4890d575-dcfd-4f3d-bf8f-4a83bed764f2',
    experience: { description: '3 - 5 years', level: 3 },
    currentPosition: 'Financial Advisor',
    userIndustry: 'Banking',
    lookingFor: 'Suppliers',
    industries: ['Banking'],
    online: true
  },
  {
    given_name: 'David',
    family_name: 'Estes',
    email: 'In@a.co.uk',
    name: 'Some Guy',
    sub: 'linkedin|354jSJhfms',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F4.jpg?alt=media&token=38630c33-714d-4c80-9dc0-9e9ce24e53c3',
    experience: { description: '3 - 5 years', level: 3 },
    currentPosition: 'Bank Teller',
    userIndustry: 'Banking',
    lookingFor: 'Clients and Sales',
    industries: ['Banking'],
    online: true
  },
  {
    given_name: 'Maris',
    family_name: 'Chen',
    email: 'eros@dignissimpharetra.co.uk',
    name: 'Some Guy',
    sub: 'linkedin|98srFKfofF',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F10.jpg?alt=media&token=62ad7949-d98b-4355-9fc8-8ae5c42ac8f8',
    experience: { description: '5 - 10 years', level: 4 },
    currentPosition: 'Copywriter',
    userIndustry: 'Banking',
    lookingFor: 'Suppliers',
    industries: ['Marketing & Advertising'],
    online: true
  },
  {
    given_name: 'Macy',
    family_name: 'Foley',
    email: 'arcu.Morbi.sit@placerat.edu',
    name: 'Some Guy',
    sub: 'linkedin|3lOdljfuns',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F9.jpg?alt=media&token=c45a8f45-838e-4181-bc79-8a1f458b777c',
    experience: { description: '5 - 10 years', level: 4 },
    currentPosition: 'Web Designer',
    userIndustry: 'Banking',
    lookingFor: 'Clients and Sales',
    industries: ['Marketing & Advertising'],
    online: true
  },
  {
    given_name: 'Cole',
    family_name: 'Hopper',
    email: 'commodo.ipsum@penatibus.edu',
    name: 'Some Guy',
    sub: 'linkedin|4kJnf9jfjK',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F5.jpg?alt=media&token=7c61459c-ac50-495d-b52f-c6190161a464',
    experience: { description: '10+ years', level: 5 },
    currentPosition: 'Life Coach',
    userIndustry: 'Banking',
    lookingFor: 'Whoever',
    industries: ['Professional Training & Coaching'],
    online: true
  },
  {
    given_name: 'Orlando',
    family_name: 'Moses',
    email: 'Integer@Crasinterdum.net',
    name: 'Some Guy',
    sub: 'linkedin|9DjFnwybfao',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F6.jpg?alt=media&token=0d398588-e3f0-40d6-9220-da56f3307787',
    experience: { description: '3 - 5 years', level: 3 },
    currentPosition: 'Mathematician',
    userIndustry: 'Banking',
    lookingFor: 'Whoever',
    industries: ['Venture Capital & Private Equity'],
    online: true
  },

  {
    given_name: 'Amery',
    family_name: 'Mooney',
    email: 'amet@InfaucibusMorbi.com',
    name: 'Some Guy',
    sub: 'linkedin|7SJsjnk89',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F10.jpg?alt=media&token=62ad7949-d98b-4355-9fc8-8ae5c42ac8f8',
    experience: { description: '1 - 3 years', level: 2 },
    currentPosition: 'Financial Advisor',
    userIndustry: 'Banking',
    lookingFor: 'Whoever',
    industries: ['Venture Capital & Private Equity'],
    online: true
  },
  {
    given_name: 'Chloe',
    family_name: 'Bell',
    email: 'fringilla.Donec@inceptoshymenaeos.co.uk',
    name: 'Some Guy',
    sub: 'linkedin|HDknf893efN',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F10.jpg?alt=media&token=62ad7949-d98b-4355-9fc8-8ae5c42ac8f8',
    experience: { description: '3 - 5 years', level: 3 },
    currentPosition: 'Angel Investor',
    userIndustry: 'Banking',
    lookingFor: 'Suppliers',
    industries: ['Venture Capital & Private Equity'],
    online: true
  },
  {
    given_name: 'Baxter',
    family_name: 'Petty',
    email: 'vitae.sodales@Sed.ca',
    name: 'Some Guy',
    sub: 'linkedin|1222Jduhandy',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F8.jpg?alt=media&token=ac4fc794-4e82-47b9-8551-d78a23863d97',
    experience: { description: '5 - 10 years', level: 4 },
    currentPosition: 'Director',
    userIndustry: 'Banking',
    lookingFor: 'Suppliers',
    industries: ['Real Estate'],
    online: true
  },
  {
    given_name: 'Ariel',
    family_name: 'Higgins',
    email: 'Cras.dolor.dolor@dapibusidblandit.edu',
    name: 'Some Guy',
    sub: 'linkedin|00JuyhdgTs',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F9.jpg?alt=media&token=c45a8f45-838e-4181-bc79-8a1f458b777c',
    experience: { description: '5 - 10 years', level: 4 },
    currentPosition: 'Human Resources Manager',
    userIndustry: 'Banking',
    lookingFor: 'Whoever',
    industries: ['Real Estate'],
    online: true
  },
  {
    given_name: 'Autumn',
    family_name: 'Valentine',
    email: 'magna.Nam@Donecest.co.uk',
    name: 'Some Guy',
    sub: 'linkedin|66HufhsnjSj',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F14.jpg?alt=media&token=e330fcbd-c343-471a-830a-9a7088a1199b',
    experience: { description: '3 - 5 years', level: 3 },
    currentPosition: 'Receptionist',
    userIndustry: 'Banking',
    lookingFor: 'Suppliers',
    industries: ['Real Estate'],
    online: true
  },
  {
    given_name: 'Quemby',
    family_name: 'Monroe',
    email: 'nulla.vulputate@nec.com',
    name: 'Some Guy',
    sub: 'linkedin|12345Wtev263633',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F3.jpg?alt=media&token=55053cf1-47d1-40c4-86d6-c8723636eb68',
    experience: { description: '0 - 6 months', level: 1 },
    currentPosition: 'Realtor',
    userIndustry: 'Banking',
    lookingFor: 'Clients and Sales',
    industries: ['Real Estate'],
    online: true
  },
  {
    given_name: 'Jasper',
    family_name: 'Ramsey',
    email: 'eleifend.nunc@a.net',
    name: 'Some Guy',
    sub: 'linkedin|TgddtDf64',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F5.jpg?alt=media&token=7c61459c-ac50-495d-b52f-c6190161a464',
    experience: { description: '3 - 5 years', level: 3 },
    currentPosition: 'Realtor',
    userIndustry: 'Banking',
    lookingFor: 'Whoever',
    industries: ['Real Estate'],
    online: true
  },
  {
    given_name: 'Adrienne',
    family_name: 'Walter',
    email: 'egestas.ligula@laciniaorci.edu',
    name: 'Some Guy',
    sub: 'linkedin|Rldojs9238',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F9.jpg?alt=media&token=c45a8f45-838e-4181-bc79-8a1f458b777c',
    experience: { description: '1 - 3 years', level: 2 },
    currentPosition: 'Estimator',
    userIndustry: 'Banking',
    lookingFor: 'Whoever',
    industries: ['Oil & Energy'],
    online: true
  },
  {
    given_name: 'Merritt',
    family_name: 'Gilmore',
    email: 'erat@ametanteVivamus.ca',
    name: 'Some Guy',
    sub: 'linkedin|Klis96DHds',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F7.jpg?alt=media&token=2da9fc18-6823-4af5-981b-9ece75e85a03',
    experience: { description: '1 - 3 years', level: 2 },
    currentPosition: 'Cost Estimator',
    userIndustry: 'Banking',
    lookingFor: 'Clients and Sales',
    industries: ['Oil & Energy'],
    online: true
  },
  {
    given_name: 'Rana',
    family_name: 'Gregory',
    email: 'posuere.vulputate@blanditcongueIn.org',
    name: 'Some Guy',
    sub: 'linkedin|2WtyTHjids',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F12.jpg?alt=media&token=f8d9fad6-8716-4aaa-ac03-1d4bce0ac759',
    experience: { description: '1 - 3 years', level: 2 },
    currentPosition: 'Systems Analyst',
    userIndustry: 'Banking',
    lookingFor: 'Whoever',
    industries: ['Oil & Energy'],
    online: true
  },
  {
    given_name: 'Whitney',
    family_name: 'Salazar',
    email: 'urna@adipiscingnonluctus.edu',
    name: 'Some Guy',
    sub: 'linkedin|089kLOdnGFdd',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F13.jpg?alt=media&token=12d3349e-91f3-41b7-bb3a-21f31a27b300',
    experience: { description: '3 - 5 years', level: 3 },
    currentPosition: 'Consultant',
    userIndustry: 'Banking',
    lookingFor: 'Clients and Sales',
    industries: ['Management Consulting'],
    online: true
  },
  {
    given_name: 'Winter',
    family_name: 'Cardenas',
    email: 'Sed.nulla@odio.com',
    name: 'Some Guy',
    sub: 'linkedin|UdknidfSS6453',
    updated_at: '2019-05-08T12:02:56.543Z',
    picture:
      'https://firebasestorage.googleapis.com/v0/b/n3-demo.appspot.com/o/n3-users%2F9.jpg?alt=media&token=c45a8f45-838e-4181-bc79-8a1f458b777c',
    experience: { description: '10+ years', level: 5 },
    currentPosition: 'Director',
    userIndustry: 'Banking',
    lookingFor: 'Whoever',
    industries: ['Management Consulting'],
    online: true
  }
]

export default USERS
