import styled from 'styled-components'
import { base, colours } from './variables'

const UIMainContainer = styled.div`
  background: ${colours.white};
  border: 0;
  max-width: 600px;
  //box-shadow: ${base.boxShadow};
  width: 100%;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0 ${base.containerPadding}/2;
  display: ${base.containerDisplay};
  flex-direction: ${base.containerFlexDirection};
  outline: none;
  margin: ${base.singleMargin} auto;
  padding-bottom: ${base.responsivePaddingBottom};
  .fade-enter {
        opacity: 0.01;
    }
  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 300ms ease-in;
  }
  .fade-exit {
      opacity: 1;
  }
    
  .fade-exit.fade-exit-active {
      opacity: 0.01;
      transition: opacity 300ms ease-in;
  }
`

export default UIMainContainer
