import React from 'react'
import { TypeH2 } from '../UIComponents/UITypography'
import IndustriesDropdowns from '../lists/IndustriesDropdowns'
import 'firebase/firestore'
import { useAuth0 } from '../react-auth0-wrapper'
import { UITopBar } from '../UIComponents/UITopBar'

const AddIndustries = props => {

  const { user, isAuthenticated }: any = useAuth0()

  if (user != null && isAuthenticated) {
    return (
      <div className='container'>
        <UITopBar />
        <div className='profile-area'>
          {user.given_name && (
            <TypeH2 light>
              {user.given_name}, what industries are you
              looking for {user.lookingFor} in?
            </TypeH2>
          )}
        </div>
        <IndustriesDropdowns user={user} history={props.history}/>
      </div>
    )
  } else {
    throw new Error('user expected in localStorage')
  }
}

export default AddIndustries
