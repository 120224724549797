import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
                                children,
                                onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                                ...initOptions
                              }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  // const storageKey = k => `auth0-${k}`

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  // const logout = () => {
  //   clearTimeout(this.tokenRenewalTimeout)
  //
  //   // Remove tokens and expiry time
  //   delete this.accessToken
  //   delete this.idToken
  //   delete this.expiresAt
  //   // Remove user profile
  //   delete this.userProfile
  //
  //   localStorage.removeItem('isLoggedIn')
  //   localStorage.removeItem(storageKey('info'))
  //
  //   this.auth0.logout({
  //     returnTo: window.location.origin
  //   })
  //
  //   // navigate to the home route
  //   history.replace('/home')
  // }
  //
  //
  // const saveToStorage = () => {
  //   const { expiresAt, idToken, userProfile, accessToken } = auth0Client
  //
  //   localStorage.setItem(
  //     storageKey('info'),
  //     JSON.stringify({
  //       expiresAt,
  //       idToken,
  //       userProfile,
  //       accessToken
  //     })
  //   )
  // }
  //
  // const loadFromStorage = () => {
  //   const key = storageKey('info')
  //   const stored = localStorage.getItem(key)
  //   if (stored) {
  //     let source = JSON.parse(stored)
  //     Object.assign(this, source)
  //     console.log('loading user from storage', stored, source.userProfile)
  //     void this.updateUserFromFireStore()
  //   } else {
  //     console.warn(`user not logged in, storage key: ${key} empty`)
  //   }
  // }
  //
  // const updateUserFromFireStore = () => {
  //   return getUser(user.sub).then(doc => {
  //     if (doc) {
  //       const userFromFireStore = doc.data()
  //       console.log(
  //         'Updating Auth userProfile with: ',
  //         JSON.stringify(userFromFireStore)
  //       )
  //       Object.assign(user, userFromFireStore)
  //       this.saveToStorage()
  //     }
  //   })
  // }


  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
