import styled from 'styled-components'
import { base, breakpoint } from './variables'

export const TypeH1 = styled.h1<{ normal?: boolean, noMargin?: boolean }>`
  font-weight: ${base.fontWeightLight};
  font-family: ${base.fontFamily};
  line-height: 1.4;
  font-size: 1.375rem;
  ${breakpoint.sm} {
    font-size: 1.5rem;
  }
  ${breakpoint.md} {
    font-size: 1.875rem;
  }
  ${breakpoint.lg} {
    font-size: 2.25rem;
  }
  margin: 0.5rem 0 0.5rem 0;
  padding: 0;
  ${props =>
  props.noMargin &&
  `
  margin: 0 auto;`}
  ${props =>
    props.normal &&
    `
  font-weight: ${base.fontWeightNormal}`}
`

export const TypeH2 = styled.h2<{ light?: boolean }>`
  font-weight: ${base.fontWeightNormal};
  font-family: ${base.fontFamily};
  font-size: 1.25rem;
  line-height: 1.4;
  ${breakpoint.sm} {
    font-size: 1.375rem;
  }
  ${breakpoint.md} {
    font-size: 1.5rem;
  }
  ${breakpoint.lg} {
    font-size: 1.75rem;
  }
  margin: 0.5rem 0 0.5rem 0;
  span.medium {
    font-weight: ${base.fontWeightSemiBold};
  }
  padding: 0;
  ${props =>
    props.light &&
    `
  font-weight: ${base.fontWeightLight}
  span.medium {
    font-weight: ${base.fontWeightNormal};
  }
  `}
`

export const TypeH3 = styled.h3<{ light?: boolean }>`
  font-weight: ${base.fontWeightNormal};
  font-family: ${base.fontFamily};
  font-size: 1.125rem;
  line-height: 1.4;
  ${breakpoint.sm} {
    font-size: 1.25rem;
  }
  ${breakpoint.md} {
    font-size: 1.375rem;
  }
  ${breakpoint.lg} {
    font-size: 1.875rem;
  }
  margin: 0.5rem 0 0.5rem 0;
  padding: 0;
  ${props =>
    props.light &&
    `
  font-weight: ${base.fontWeightLight}`}
`

export const TypeH4 = styled.h4<{ light?: boolean }>`
  font-weight: ${base.fontWeightNormal};
  font-family: ${base.fontFamily};
  font-size: 1rem;
  line-height: 1.4;
  ${breakpoint.sm} {
    font-size: 1.125rem;
  }
  ${breakpoint.md} {
    font-size: 1.25rem;
  }
  ${breakpoint.lg} {
    font-size: 1.375rem;
  }
  margin: 0.5rem 0 0.5rem 0;
  padding: 0;
  ${props =>
    props.light &&
    `
  font-weight: ${base.fontWeightLight}`}
`

export const TypeH5 = styled.h5`
  font-weight: ${base.fontWeightNormal};
  font-family: ${base.fontFamily};
  font-size: 0.825rem;
  line-height: 1.4;
  ${breakpoint.sm} {
    font-size: 0.9375rem;
  }
  ${breakpoint.md} {
    font-size: 1rem;
  }
  ${breakpoint.lg} {
    font-size: 1.125rem;
  }
  margin: 0.5rem 0 0.5rem 0;
  padding: 0;
`

export const TypeH6 = styled.h6<{ noMargin?: boolean, italic?: boolean }>`
  font-weight: ${base.fontWeightNormal};
  font-family: ${base.fontFamily};
  
  font-size: 0.675rem;
  line-height: 1.4;
  color: ${props => props.theme.textColour}
  ${breakpoint.sm} {
    font-size: 0.75rem;
  };
  ${breakpoint.md} {
    font-size: 0.825rem;
  };
  ${breakpoint.lg} {
    font-size: 0.875rem;
  };
  margin: 0.5rem 0 0.5rem 0;
  padding: 0;
  ${props =>
  props.noMargin &&
  `
  margin: 0 auto;`}
  ${props =>
  props.italic &&
  `
  font-style: italic;`}
`
