import React from "react"
import { useAuth0 } from "../react-auth0-wrapper"
import { TypeH1 } from '../UIComponents/UITypography'
import UIProfileImage from '../UIComponents/UIProfileImage'
import { UITopBar } from '../UIComponents/UITopBar'

const Profile = () => {

  const { loading, user }: any = useAuth0()

  if (loading || !user) {
    return <h3>"Loading..."</h3>
  }

  return (
    <>
      <div className='container'>
        <div className='profile-area'>
          <UITopBar />
          <TypeH1>Hello {user.name}</TypeH1>
          <div>
              <UIProfileImage src={user.picture} alt='Profile Image' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile;
