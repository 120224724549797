import { observable } from 'mobx'
import IUser from '../models/models'

export const MatchesStore = observable(({
  given_name: null,
  family_name: null,
  email: null,
  picture: null,
  experience: { description: '', level: 1 },
  currentPosition: '',
  lookingFor: null,
  industries: [],
  matched: false,
  id: null
} as unknown) as IUser)


// this is a bit of a mess!
export const resetMatch:{ sub: string; clientID: string; gender: string; userIndustry: string; created_at: string; lookingFor: string; locale: string; experience: { level: number; description: string }; chatFinished: boolean; identities: any[]; user_metadata: undefined; updated_at: string; nickname: string; conversationsFinished: string; email: string; app_metadata: undefined; email_verified: boolean; currentPosition: string; chatStarted: boolean; matchedPeople: any[]; readyToChat: boolean; given_name: string; picture: string; user_id: string; industries: any[]; name: string; online: boolean; matched: boolean; family_name: string; username: string } = {
  app_metadata: undefined,
  chatFinished: false,
  chatStarted: false,
  clientID: '',
  conversationsFinished: '',
  created_at: '',
  email_verified: false,
  gender: '',
  identities: [],
  locale: '',
  name: '',
  nickname: '',
  online: false,
  readyToChat: false,
  sub: '',
  updated_at: '',
  userIndustry: '',
  user_metadata: undefined,
  username: '',
  given_name: '',
  family_name: '',
  email: '',
  picture: '',
  experience: { description: '', level: 1 },
  currentPosition: '',
  lookingFor: '',
  industries: [],
  matched: false,
  matchedPeople: [],
  user_id: ''
}
