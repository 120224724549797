import styled from 'styled-components'
import { base } from './variables'

const UIHorizontalRule = styled.div`
  width: 100%;
  text-align: center;
  display: block;
  flex-direction: ${base.containerFlexDirection};
  outline: none;
  border-bottom: 1px solid #cccccc;
  margin: 1.5rem auto;
`

export default UIHorizontalRule
