import styled from 'styled-components'
import { base, breakpoint, colours } from './variables'

const UITimer = styled.div`
  width: 100%;
  text-align: center;
  display: block;
  align-items: center;
  margin-top: 0.5rem;
  ${breakpoint.md} {
    margin-top: 1.25rem;
  }
  ${breakpoint.lg} {
    margin-top: 2.5rem;
  }
  padding: 0.25rem;
  @-webkit-keyframes pulse {
    0% {transform: scale(1.1)}
    30% {transform: scale(1.0)}
    100% {transform: scale(1.0)}
  }
  @keyframes pulse {
    0% {transform: scale(1.1)}
    30% {transform: scale(1.0)}
    100% {transform: scale(1.0)}
  }
  h1 {
    padding: 0;
    margin: 0 auto;
    font-weight: ${base.fontWeightLight};
    font-family: ${base.fontFamily};
    font-size: 3.25rem;
    transition: color 0.5s ease-out;
    &.ending {
      -webkit-animation-name: pulse;
      -webkit-animation-timing-function: ease-in-out;
      -webkit-animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
      animation-name: pulse;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      color: ${colours.red};
    }
     &.finished {
        padding-top: 0.325rem;
        padding-bottom: 0.325rem;
        font-size: 2rem;
     }
  }
`

export default UITimer
