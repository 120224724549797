import React, { useEffect } from 'react'
import { createMuiTheme } from '@material-ui/core'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PrivateRoute } from "./privateRoute"
import Home from './Home/Home'
import UIMainContainer from './UIComponents/UIMainContainer'
import { MuiThemeProvider } from '@material-ui/core/styles'
import ProfileDetails from './ProfileDetails/ProfileDetails'
import { history } from './history'
import ChooseOutcomes from './ChooseOutcomes/ChooseOutcomes'
import AddIndustries from './AddIndustries/AddIndustries'
import Search from './Search/Search';
import Profile from './Profile/Profile'
import { Provider } from 'mobx-react'
import { UserProfileStore } from './stores/UserProfileStore'
import { useAuth0 } from './react-auth0-wrapper'
import { loadFromStorage } from './Firestore/Firestore'
import Chat from './Chat/Chat'
// @ts-ignore
import { MatchesStore } from './stores/MatchesStore'

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1b2d2d',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#256276',
      light: '#5790a5',
      dark: '#00384a',
      contrastText: '#FFF'
    },
    error: {
      main: '#d40022',
      light: '#ff6464',
      dark: '#670011',
      contrastText: '#FFF'
    }
  }
})

// const auth = Auth.instance
// auth.init()

function App() {

  const { user } = useAuth0()

  useEffect(() => {
    const fn = async () => {
      if (user) {
        await loadFromStorage(user)
      }
    };
    fn();
  }, [user]);

  return (
    <MuiThemeProvider theme={theme}>
      <Provider UserProfileStore={UserProfileStore} MatchesStore={MatchesStore}>
      <UIMainContainer>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <PrivateRoute path="/profile" component={Profile} history={history} />
          <PrivateRoute path="/profile-details" component={ProfileDetails} history={history} />
          <PrivateRoute path="/choose-outcomes" component={ChooseOutcomes} history={history} />
          <PrivateRoute path="/add-industries" component={AddIndustries} history={history} />
          <PrivateRoute path="/search" component={Search} history={history} />
          <PrivateRoute path="/chat" component={Chat} history={history} />
        </Switch>
      </BrowserRouter>
      </UIMainContainer>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App

