import * as firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/firestore';
import { FIREBASE_CONFIG } from './firestore-variables'
import IUser from '../models/models'
import { Collection, Document, initFirestorter, Mode } from 'firestorter'
import { UserProfileStore } from '../stores/UserProfileStore'

firebase.initializeApp(FIREBASE_CONFIG)

initFirestorter({ firebase: firebase })
// collection
export const users = new Collection('users', {
  mode: Mode.On
})

// store to hold users
export const usersStore = {
  users: users
}

const storageKey = k => `auth0-${k}`

export const isUserMatch = (profile: IUser, doc: any) => {
  let exists: boolean = false
  console.log(doc)

  if (doc === undefined) {
    console.log("User doesn't exist")
  } else {
    exists = true
    console.log('User exists and is logged in')
  }
  return [exists, doc]
}

export const getUser = async (id: string) => {
  const docRef = firebase
    .firestore()
    .collection('users')
    .doc(id)

  return docRef.get().then(doc => {
    if (!doc.exists) {
      return null
    } else {
      console.log('Document found!: ', doc.data())
      return doc
    }
  })
}

const saveToStorage = (user) => {
    localStorage.setItem(
      storageKey('info'),
      JSON.stringify({
        user
      })
    )
    console.log(localStorage.getItem('auth0-info'))
  }

export const loadFromStorage = async (user: IUser) => {
  const key = storageKey('info')
  const stored = localStorage.getItem(key)
  if (stored) {
    let source = JSON.parse(stored)
    Object.assign(UserProfileStore, source)
    console.log('loading user from storage', stored, source.sub)
    await updateUserFromFireStore(user)
  } else {
    console.warn(`user not logged in, storage key: ${key} empty`)
    // do normal upsert check if it is on firestore if not create etc
    upsertUser(user)
  }
}

const updateUserFromFireStore = (user: IUser) => {
  return getUser(user.sub).then(doc => {
    if (doc) {
      const userFromFireStore = doc.data()
      console.log(
        'Updating Auth userProfile with: ',
        JSON.stringify(userFromFireStore)
      )
      Object.assign(user, userFromFireStore)
      saveToStorage(user)
    }
  })
}

export const setUserInLocalStorage = user => {
  console.log("set in LS")
  // Object.assign(user, user)
  saveToStorage(user)
}

// set online flag true or false
export const setUserOnlineStatus = (user: IUser, online: boolean) => {
  return updateUser(user, { online })
}

// update current user
export const updateUser = async (user: IUser, fields: Partial<IUser>) => {
  Object.assign(user, fields)
  Object.assign(UserProfileStore, fields)
  console.log(user)
  setUserInLocalStorage(user)

  if(user) {
    return firebase
      .firestore()
      .doc(`users/${user.sub}`)
      .update(fields)
      .catch(err => {
        console.log(`Couldn't update user: `, err)
        throw new Error("Document doesn't exist")
      })
  }
}

// add current user
export const upsertUser = (user: IUser) => {

  setUserOnlineStatus(user, true)
    .catch(error => {
    console.log("Error returned: ", error)
    if (error) {

      const doc = new Document(`users/${user.sub}`)
      // const doc = firebase.firestore().doc(`users/${user.sub}`)

      user = {
        ...user,
        online: true
      }

      return doc
        .set(user)
        .then(() => {
          console.log("doc: ", doc.id)
        })
        .catch(err => {
          console.log(`Couldn't save user: `, err)
        }).finally(() => {
          // set user in store
          Object.assign(UserProfileStore, user)
          console.log("User in store first save: ", UserProfileStore.sub)
        })
    }
  })
}
