import styled from 'styled-components'
import { base, colours, sizes } from './variables'

const UIProfileName = styled.div`
  width: auto;
  text-align: center;
  padding: 0.5rem 2rem;
  color: #FFF;
  background-color: ${colours.darkGrey};
  border-radius: 1.5rem;
  border: 1px solid ${colours.lightGrey};
  display: inline-block;
  top: -3rem;
  position: relative;
  font-weight: ${base.fontWeightSemiBold};
  font-family: ${base.fontFamily};
  font-size: 1.125rem;
  @media (max-width: ${sizes.smMd}px) {
      font-size: 0.9375rem;
    }
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin: 0 auto;
`

export default UIProfileName
