const ICEBREAKERS = [
  { question: 'What motivated you to come to this event?' },
  { question: 'What projects are you currently working on?' },
  { question: 'If you could meet any historical figure, either living or deceased, who would you choose and why?' },
  { question: 'Who is your personal hero?' },
  { question: 'What’s a skill you learned when you were young that you still use today?' },
  { question: 'What talent or potential do you have that is not fully realized at your current job?' },
  { question: 'What are some of the biggest challenges in your market?' },
  { question: 'What’s your proudest accomplishment?' },
  { question: 'What’s the biggest block to selling/marketing in your business?' },
  { question: 'Tell me how you got into your business?' },
  { question: 'What is something you do that most people would not expect?' }
]

export default ICEBREAKERS
