import styled from 'styled-components'
import { base, breakpoint, colours, sizes } from './variables'

const UIHeading = styled.div`
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid ${colours.lightGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: calc(${base.singleMargin} / 2) auto calc(${base.singleMargin}*2) auto;

  @media (max-width: ${sizes.smMd}px) {
    margin-bottom: ${base.singleMargin};
  }
  h4 {
    flex-grow: 1;
    padding: 0 0.5rem;
    text-align: center;
    color: #222;
    letter-spacing: -0.025rem;
    align-self: center;
    @media (max-width: ${sizes.smMd}px) {
      font-size: 0.875rem;
      padding-top: 0.25rem;
    }
    ${breakpoint.md} {
      margin-left: -32px;
    }
    ${breakpoint.lg} {
      margin-top: 0.325rem;
    }
  }
`

export default UIHeading
