import React, { useEffect, useState } from 'react'
import { TypeH2, TypeH3, TypeH6 } from '../UIComponents/UITypography'
import { useAuth0 } from '../react-auth0-wrapper'
import { UITopBar } from '../UIComponents/UITopBar'
import UIButton from '../UIComponents/UIButton'
import { inject, observer } from 'mobx-react'
import UISpacer from '../UIComponents/UISpacer'
import { Grid } from '@material-ui/core'
import UIProfileImage from '../UIComponents/UIProfileImage'
import UITimer from '../UIComponents/UITimer'
import { UIIceBreakers } from '../UIComponents/UIIceBreakers'
import ICEBREAKERS from '../data/ice-breakers';

const Timer = ({ secs }) => {

  let formattedSeconds = ' '

    console.log(secs)
    const minutes = Math.floor(secs / 60)

    const secondsLeft = secs - minutes * 60
    if (secondsLeft < 10) {
      formattedSeconds = `0${secondsLeft}`
    } else {
      formattedSeconds = `${secondsLeft}`
    }

  let formattedTimer = `${minutes}:${formattedSeconds}`

  return (
    <h1 className={(secs < 20 && secs > 0) ? 'ending' : secs === 0 ? 'finished' : ''}>
      {secs !== 0 ? formattedTimer : "Time's Up!"}
    </h1>
  )
}

const Chat  = inject('UserProfileStore', 'MatchesStore')(
  observer(({ UserProfileStore, MatchesStore, history }) => {

  const { user, isAuthenticated }: any = useAuth0()
  const iceBreakers = ICEBREAKERS
  const [seconds, setSeconds] = useState(300);

  useEffect(() => {
    let interval: number
    if(seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1)
      }, 1000);
      return () => clearInterval(interval);
    } else {
      interval = 0
    }
  }, [seconds]);


  const newMatch = () => {
    history.push('/search')
  }

  const theme = {
    textColour: '#AAA'
  }

  if (user !== null && isAuthenticated) {

    return (
      <div className='container'>
        <UITopBar />
        <div className='profile-area'>
            <TypeH2 light>
              You have 5 minutes to talk to <span className="medium">{MatchesStore.given_name}</span>
            </TypeH2>
          <Grid container justify="center" spacing={1}>
            <Grid item xs={3}>
              <UIProfileImage small src={user.picture} alt='Match Image' />
            </Grid>
            <Grid item xs={6}>
              <UITimer><Timer secs={seconds}/></UITimer>
            </Grid>
            <Grid item xs={3}>
              <UIProfileImage small src={MatchesStore.picture} alt='Match Image' />
            </Grid>
          </Grid>
        </div>

        <TypeH3 light>Ice Breakers</TypeH3>
        <TypeH6 theme={theme} noMargin italic>Swipe left for more</TypeH6>
        <UIIceBreakers questions={iceBreakers}/>

        <UISpacer/>

        <div className='buttons-wrap'>
          <UIButton
            outline
            onClick={newMatch}
          >
            New Match
          </UIButton>
        </div>
      </div>
    )
  } else {
    throw new Error('user expected in localStorage')
  }
}))

export default Chat
