import React from "react"
import { useAuth0 } from "./react-auth0-wrapper"
import UIButton from './UIComponents/UIButton'
import UISpacer from './UIComponents/UISpacer'
import { Link } from 'react-router-dom'

const HomeNav = () => {

  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  return (
    <div>
      {!isAuthenticated && (

        <UISpacer>
          <UIButton
            onClick={() =>
            loginWithRedirect({})
          }
          >Log in</UIButton>
        </UISpacer>
      )}

      {isAuthenticated &&
      <UISpacer>
        <UIButton onClick={() => logout()} outline>Log out</UIButton>
        <Link className="link" to="/profile-details"><UIButton>Continue</UIButton></Link>
      </UISpacer>}

    </div>
  )
}

export default HomeNav
