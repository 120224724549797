import styled from 'styled-components'
import { base } from './variables'

const UILogo = styled.img<{ loggedin?: boolean }>`
  width: 100px;
  height: 100px;
  text-align: center;
  display: block;
  flex-direction: ${base.containerFlexDirection};
  outline: none;
  margin: 0.75rem auto;
  ${props =>
    props.loggedin &&
    `
    flex-basis: 42px;
    width: 42px;
    height: 42px;
    margin: 0 auto;
  `}
`

export default UILogo
