import React, { useEffect, useState } from 'react'
import { TypeH2, TypeH5 } from '../UIComponents/UITypography'
import UIButton from '../UIComponents/UIButton'
import INDUSTRIES from '../data/industries'
import EXPERIENCE from '../data/experience-level'
import { TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import UIFullWidthDiv from '../UIComponents/UIDropdown'
import UISpacer from '../UIComponents/UISpacer'
import { updateUser } from '../Firestore/Firestore'
import { useAuth0 } from '../react-auth0-wrapper'
import { UITopBar } from '../UIComponents/UITopBar'

const ProfileDetails = ({history}) => {

  const { user, isAuthenticated, loading }: any = useAuth0()

  const setInitialValues = (prop) => {
    if(prop !== undefined) {
      return prop
    } else {
      return null
    }
  }

  const [currPosition, setCurrPosition] = useState(setInitialValues(user.currentPosition))
  const [userIndustry, setUserIndustry] = useState(setInitialValues(user.userIndustry))
  const [experienceLevel, setExperienceLevel] = useState(setInitialValues(user.currentPosition))
  const [inputError, setInputError] = useState(false)

  const goToNextPage = () => {

    let fields = {
      currentPosition: currPosition,
      matchedPeople: [],
      matched: false
    }

    let allFields:any = fields

    if(experienceLevel === null) {
      allFields = {
        ...allFields,
        experience: {
          level: EXPERIENCE[0].ind,
          description: EXPERIENCE[0].description,
        }
      }
    }

    if(userIndustry === null) {
      allFields = {
        ...allFields,
        userIndustry: 0
      }
    }

    void updateUser(user, allFields).then(() => {
      history.push('/choose-outcomes')
    })
  }

  const industriesDropdown = INDUSTRIES.map((industry, index) => (
    <option
      value={industry.name}
      id={`${index}-${industry.name}`}
      key={`${index}-${industry.name}`}
    >
      {industry.name}
    </option>
  ))

  const experienceDropdown = EXPERIENCE.map((experience, index) => (
    <option
      value={experience.ind}
      id={`${index}-${experience.level}`}
      key={`${index}-${experience.description}`}
    >
      {experience.description}
    </option>
  ))

  const handleIndustryChange = event => {
    setUserIndustry(event.target.value)
    void updateUser(user, {
      userIndustry: event.target.value
    })
  }

  const handleExperienceChange = (event) => {
    const fields = {
      experience: {
        level: EXPERIENCE[event.target.value].ind,
        description: EXPERIENCE[event.target.value].description
      }
    }
    setExperienceLevel(event.target.value)
    void updateUser(user, fields)
  }

  const handlePositionChange = event => {

    const val = event.target.value
    setCurrPosition(event.target.value)

    // validation
    val.length < 2 ? setInputError(true) : setInputError(false)
  }

  if (loading || !user) {
    return <h3>"Loading..."</h3>
  }

  return (
    <div className='container'>
      <UITopBar />
      <div className='profile-area'>
        <TypeH2 light>Hi {user.given_name}</TypeH2>
        <TypeH5>Add extra details to your profile.</TypeH5>
      </div>


      {user && isAuthenticated && (
        <>
          <UIFullWidthDiv>
            <FormControl className='form-control'>
              <TextField
                id='current-position'
                name='current-position'
                value={currPosition !== null ? currPosition : ''}
                label='Current Position'
                type='text'
                error={inputError}
                margin='normal'
                onChange={handlePositionChange}
              />
            </FormControl>
          </UIFullWidthDiv>

          <UIFullWidthDiv>
            <FormControl className='form-control'>
              <InputLabel shrink htmlFor='industry-select-placeholder'>
                Select Industry
              </InputLabel>
              <Select
                native
                name='experience'
                value={userIndustry !== null ? userIndustry : ''}
                id='industry-select-placeholder'
                onChange={handleIndustryChange}
              >
                {industriesDropdown}
              </Select>
            </FormControl>
          </UIFullWidthDiv>

          <UIFullWidthDiv>
            <FormControl className='form-control'>
              <InputLabel shrink htmlFor='experience-select-placeholder'>
                Select Experience
              </InputLabel>
              <Select
                native
                name='experience'
                value={experienceLevel !== null ? experienceLevel : ''}
                id='experience-select-placeholder'
                className='select-box experience-select'
                onChange={handleExperienceChange}
              >
                {experienceDropdown}
              </Select>
            </FormControl>
          </UIFullWidthDiv>
        </>
        )}

      <UISpacer half>
        <UIButton onClick={goToNextPage} disabled={inputError}>Continue</UIButton>
      </UISpacer>
    </div>
    )
  }

export default ProfileDetails
