import React from 'react'
import { Grid } from '@material-ui/core'
import { TypeH1, TypeH3 } from '../UIComponents/UITypography'
import HomeNav from '../HomeNav'
import { useAuth0 } from '../react-auth0-wrapper'
import Profile from '../Profile/Profile'
import logoImg from '../assets/images/n3-logo.png'
import UILogo from '../UIComponents/UILogo'
import UIHorizontalRule from '../UIComponents/UIHorizontalRule'

const Home = () => {

  const { isAuthenticated } = useAuth0()

    return (
      <Grid container justify='center'>
        {!isAuthenticated && (
          <>
            <Grid item xs={12}>
              <UILogo src={logoImg} />
              <UIHorizontalRule />
              <TypeH1>Welcome to N3 Networking!</TypeH1>
              <TypeH3>Please login below</TypeH3>
            </Grid>
            <HomeNav/>
          </>
        )}
        {isAuthenticated && (
          <>
            <Grid item xs={12}>
              <Profile />
            </Grid>
            <HomeNav/>
          </>
        )}
      </Grid>
    )
}

export default Home
